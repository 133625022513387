import "core-js/stable";
import "regenerator-runtime/runtime";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("controllers");

const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

import 'stylesheets/font-awesome';
import 'stylesheets/public.scss';

window.dispatchMapsEvent = function (...args) {
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}
